var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['panel', 'donation_whatwedo',
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
		_vm.block.appearance? _vm.block.appearance : ''
	]},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12"},[_c('h2',[_vm._v(_vm._s(_vm.block.block_title))])])])]):_vm._e(),_vm._v(" "),_vm._l((this.block.items),function(item,index){return _c('div',{key:index},[_c('ul',{class:{'characteriscics primary row': index % 2 === 1, 'characteriscics  row': index % 2 === 0}},[_c('li',{class:item.sub_items.length === 3 ? 'col-12 col-lg-6' : 'col-12 col-lg-8'},[_c('div',{staticClass:"overview"},[_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(item.description)+"\n\t\t\t\t\t\t")]),_vm._v(" "),(item.text)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(item.text)}}):_vm._e()])]),_vm._v(" "),_vm._l((item.sub_items),function(sub_item,index){return _c('li',{key:index,class:item.sub_items.length === 3 ? 'col col-12 col-md-4 col-lg-2' : 'col col-12 col-md-6 col-lg-2'},[_c('div',{staticClass:"item text-center text-md-left"},[_c('div',{staticClass:"digit"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(sub_item.number)+"\n\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(sub_item.number_description)+"\n\t\t\t\t\t\t")])])])})],2)])}),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('dynamic-button',{attrs:{"button":_vm.block.button,"block-id":_vm.block.id}})],1)])]):_vm._e(),_vm._v(" "),(_vm.isFooterVisible)?_c('img',{staticClass:"wwd_01",attrs:{"src":"/donation/wedo_01.svg"}}):_vm._e(),_vm._v(" "),(_vm.isFooterVisible)?_c('img',{staticClass:"wwd_02",attrs:{"src":"/donation/wedo_02.svg"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }