<template>
	<div
		:class="['panel', 'donation_whatwedo',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : ''
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12">
						<h2>{{ block.block_title }}</h2>
					</div>
				</div>
				<!-- <nuxt-link class="panel_control" to="#"></nuxt-link> -->
			</div>

			<div v-for="(item, index) in this.block.items" :key="index">
				<ul
					class
					:class="{'characteriscics primary row': index % 2 === 1, 'characteriscics  row': index % 2 === 0}"
				>
					<li :class="item.sub_items.length === 3 ? 'col-12 col-lg-6' : 'col-12 col-lg-8'">
						<div class="overview">
							<div class="title">
								{{ item.description }}
							</div>
							<div v-if="item.text" class="description" v-html="item.text" />
						</div>
					</li>
					<!-- :class="item.sub_items.length === 3 ? 'col col-12 col-md-4 col-lg-2' : 'col-6 col-sm-6 col-lg-2'" -->
					<li
						v-for="(sub_item, index) in item.sub_items"
						:key="index"
						:class="item.sub_items.length === 3 ? 'col col-12 col-md-4 col-lg-2' : 'col col-12 col-md-6 col-lg-2'"
						class
					>
						<div class="item text-center text-md-left">
							<div class="digit">
								{{ sub_item.number }}
							</div>
							<div class="title">
								{{ sub_item.number_description }}
							</div>
						</div>
					</li>
				</ul>
			</div>

			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12">
						<dynamic-button
							:button="block.button"
							:block-id="block.id"
						/>
					</div>
				</div>
			</div>

			<img v-if="isFooterVisible" class="wwd_01" src="/donation/wedo_01.svg">
			<img v-if="isFooterVisible" class="wwd_02" src="/donation/wedo_02.svg">
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'DonationWhatWeDo',
	mixins: [panel],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/dev/donation-whatwedo";
</style>
